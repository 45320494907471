import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'rgba(255, 255, 255, 0.8)',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
    position:'absolute',
    top:'0px',
    transition:'all .5s ease-in-out',
    opacity: 1
  })),
  logo2: css(mq({
    position:'absolute',
    top:'0px',
    transition:'all .5s ease-in-out',
    opacity: 1
  })),
  logoImg: css(mq({
    display: 'block',
    height: [120, null, 150],
  })),
  logoImg2: css(mq({
    display: 'block',
    height: [40, null, 50],
    marginTop: '2px'
  })),
  logoScrolled: css(mq({
    top:'-100px',
    opacity: 0
  })),
  logoScrolled2: css(mq({
    //top:'-100px',
    opacity: 1
  })),
}